import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import { seoData } from "../shared/seoData"
import MentoringOffering from "../components/Mentoring/MentoringOffering"
import { TeamMembersData } from "../shared/constants"
import { PageTitles } from "../shared/constants"

export default class MaterialUi extends React.Component {
  render() {
    const seo = seoData.materialUi
    return (
      <>
        <SEO
          title={seo.title}
          description={seo.metaDescription}
          pathname={seo.pathname}
        />
        <Layout selectedTabValue={false}>
          <MentoringOffering title={PageTitles.materialUi.title} instructors={TeamMembersData} topics={topics}/>
        </Layout>
      </>
    )
  }
}

const topics = [
  "Learning the core presentational topics such as Layout, Grid System, and Box",
  "Practicing the CSS in JS styling solution",
  "Creating a theme from scratch and override colors, padding, margins, fonts, and more",
  "Work with components such as TextField, Dialog, Drawer, Switch, Menu, Tabs, Card, Snackbar, and many more",
  "Develop responsive layouts that work well on mobiles and large screens",
  "Learning to override the styles using className and classes prop",
  "Working with responsive breakpoints to change styling and show/hide components",
]
